import React from 'react';
import Navbar from '../common/navbar';
import Footer from '../common/footer';
import { Carousel, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Home = () => {
	return (
		<div className='home'>
			<Navbar />
			<Carousel>
				<Carousel.Item>
					<img
						className='d-block w-100'
						src='/campus/image1.jpg'
						alt='First '
					/>

					<Carousel.Caption></Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className='d-block w-100'
						src='/campus/image2.jpg'
						alt='Second'
					/>

					<Carousel.Caption></Carousel.Caption>
				</Carousel.Item>
			</Carousel>
			<section
				className='aboutSJCE container-fluid'
				style={{ backgroundImage: `url("/campus/c2.jpg")` }}>
				<div className='container'>
					<div className='text'>
						<h1>Our Campus</h1>
						<p>
						JSS Academy of Technical Education Noida (JSSATEN) is one of the leading Technical Institutions in the National Capital Region in the State of Uttar Pradesh. Established in the year 1998 by JSS Mahavidyapeetha, Noida, the Institution has set bench marks every year, and grown into an Institution of Excellence in Technical Education. Located in the central part of NOIDA, JSSATEN has become a household name for its excellence in Discipline, Teaching, Training and Placement. Today, JSSATEN has total student strength of over 4000, who are mentored by more than 250 Faculty Members. The Campus has finest accommodation for girls and boys.
						 
						</p>
						{/* <p>The foundation Stone of this campus was laid by the Honorable Chief Minister of Delhi Mrs. Sheila Dixit on 24th May 2006. The campus was built in a record time of 18 months by the Public Works Department (PWD). The students have access to all facilities like Centralized Air-Conditioned Labs, well-equipped Library, Student Study Center, RO System, lush green playground, and Rain harvesting system.</p> */}
						<p>
						JSS Academy of Technical Education Noida aims to become an Institution of excellence in imparting quality Outcome Based Education that empowers the young generation with Knowledge, Skills, Research, Aptitude and Ethical values to solve Contemporary Challenging Problems
						</p>
						<p>
						The Institution has MOUs with Colorado Heights University, Denver, USA for student exchange program. IBM Centre of Excellence, Nokia Mobile Innovation Labs, PLMCC and Schneider Electric Substation Automation Labs are additional facilities for the students to innovate new ideas. JSS Academy has Doctoral Programs in Computer Science & Engineering, Mechanical Engineering, Electronics & Communication Engineering and Physical Sciences under Dr APJ Abdul Kalam Technical University.
						</p>

						<Link
							to='/about-us'
							onClick={() => window.scrollTo(0, 0)}
							className='redirect-btn'>
							Learn more
						</Link>
					</div>
					<div className='about-img'>
						<img
							// className="d-block"
							src='/campus/image.jpg'
							alt='Campus'
						/>
					</div>
				</div>
			</section>
			<section className='whySJCE container'>
				<h1>Why JSS Noida ?</h1>
				<div className='f-row'>
					<div className='f-col'>
						<div className='image-wrapper'>
							<img src='/icons/courses.svg' />
						</div>

						<div>
							<h2>Courses Offered</h2>
							<p>
							   JSSATEN offers Under Graduate Courses in 9 branches of engineering, namely: 
							   CSE, CSE(AI&ML), CSE (Data Science), ECE, CE, IT, EEE, EE and ME. 
							</p>
							<p>
							   JSSATEN also offers 4 Post Graduate courses, namely, 
							   MTech. in AI&ML and Production engineering, MBA & MCA.
							</p>
							<p>
							   JSSATEN is also a college recognized for research programs in computer science and engineering, 
							   Mechanical engineering, and Electronics and communication engineering.
							</p>

							<Link
								to='/about-us'
								onClick={() => window.scrollTo(0, 0)}
								className='learn'>
								Learn more
							</Link>
						</div>
					</div>
					<div className='f-col'>
						<div className='image-wrapper'>
							<img src='/icons/alumni.svg' />
						</div>

						<div>
							<h2>Our Alumni</h2>
							<p>
							   Our success story as an Institution of repute is also penned by our Alumni 
							   who have made it big and they are now pursuing glowing careers in highly reputed 
							   organizations in Private as well as Government sectors. Many of our alumni are 
							   presently posted in administrative services such as IFS, IAS, IPS, IES, IRS, PCS etc. 
							   while many other have excelled as successful entrepreneurs.
							</p>
							<Link
								to='/about-us'
								onClick={() => window.scrollTo(0, 0)}
								className='learn'>
								Learn more
							</Link>
						</div>
					</div>
				</div>
				<div className='f-row'>
					<div className='f-col'>
						<div className='image-wrapper'>
							<img src='/icons/societies.svg' />
						</div>

						<div>
							<h2>Societies and Events</h2>
							<p>
								Culture is intricately interwoven into life at JSS Noida.
								Extracurricular activities provide a setting to become involved
								and to interact with other students, thus leading to increased
								learning and inclusive development.
							</p>
							<p>
								Here, when people lead a busy life, the multifarious clubs bring
								a breeze of happiness, relaxation, pleasure, and many joyous
								moments.
							</p>
							<Link
								to='/about-us'
								onClick={() => window.scrollTo(0, 0)}
								className='learn'>
								Learn more
							</Link>
						</div>
					</div>
					<div className='f-col'>
						<div className='image-wrapper'>
							<img src='/icons/recruiters.svg' />
						</div>
						<div>
							<h2>Esteemed Recruiters</h2>
							<p>
								The batch profile consists of students from computer science and
								electronics background. Students at JSS Noida throughout
								their graduation and Post-Graduation indulge into various skill
								development programs, internships, training, and cultural as
								well as technical contests. Students have continued to receive
								placement & internship offers from various reputed companies
								from different sectors of the industry.
							</p>
							<Link
								to='/past-recruiters'
								onClick={() => window.scrollTo(0, 0)}
								className='learn'>
								Learn more
							</Link>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Home;
