import React from 'react';
import NavBar from './../common/navbar';
import Footer from './../common/footer';
import Coordinator from './common/coordinator';

const Secretaries = () => {
	const plSecretaries = [
		{
			imageURL : '/team/secretaries/gaurav.jpg',
			name : 'Gaurav Soni (CE)',
			mobile : '7393087509',
			email : 'rajsoni272001@gmail.com',
			linkedinURL : 'https://www.linkedin.com/in/gaurav-soni-667b4b190'
		},
		{
			imageURL : '/team/secretaries/rishab.jpg',
			name : 'Rishabh Singh (CSE)',
			mobile : '9711344554',
			email : 'Rishabhsingh0019@gmail.com',
			linkedinURL : 'https://www.linkedin.com/in/rishabh-singh-318201203'
		},
		{
			imageURL : '/team/secretaries/shubham.jpg',
		    name : 'Shubham Madwal (CSE)',
		    mobile : '9891289966',
		    email : 'shubhammadwal26@gmail.com ',
		    linkedinURL : 'https://www.linkedin.com/in/shubham-madwal-94760224b/'
		},
		{
			imageURL : '/team/secretaries/chinmayee.jpg',
			name : 'Chinmayee Verma (ECE)',
			mobile : '9971254654',
			email : 'chinmayeeverma8848@gmail.com',
			linkedinURL : 'https://www.linkedin.com/in/chinmayee-verma-1270951ba'
		},
		{
			imageURL : '/team/secretaries/shani.jpg',
			name : 'Shani Patel (ECE)',
			mobile : '9682959780',
			email : 'shanipatel.delhi@gmail.com',
			linkedinURL : 'https://www.linkedin.com/in/realshanipatel'
		},
		{
			imageURL : '/team/secretaries/yashi.jpg',
			name : 'Yashi Agarwal (ECE)',
			mobile : '8126531274',
			email : 'yashiagrawal.agra2000@gmail.com',
			linkedinURL : 'https://www.linkedin.com/in/yashi-agarwal-0031341a8'
		},
		{
			imageURL : '/team/secretaries/anubhav.jpg',
			name : 'Anubhav Singh (EE)',
			mobile : '8529747569',
			email : 'anubhavsingh9547@gmail.com',
			linkedinURL : 'https://www.linkedin.com/in/anubhav-singh-7a43321aa'
		},
		{
			imageURL : '/team/secretaries/ali.jpg',
			name : 'Mohammad Ali (EEE)',
			mobile : '7310372604',
			email : 'alim44592@gmail.com',
			linkedinURL : 'https://www.linkedin.com/in/mohammad-ali-3612841a3'
		},
		{
			imageURL : '/team/secretaries/akash.jpg',
			name : 'Akash Gupta (IT)',
			mobile : '6387612149',
			email : 'akashgupta2832@gmail.com',
			linkedinURL : 'https://www.linkedin.com/in/akash-gupta-381576212'
		},
		{
			imageURL: '/team/secretaries/chaudhary.jpg',
			name: 'Dev Chaudhary (IT)',
			mobile: '7983036064',
			email: 'i.devchaudhary17@gmail.com',
			linkedinURL: 'https://www.linkedin.com/in/dev-chaudhary-9b551a1b6'
		},
		{
			imageURL: '/team/secretaries/akanksha.jpg',
			name: 'Akanksha Singh (ME)',
			mobile: '8287010890',
			email: 'as27072000@gmail.com',
			linkedinURL: 'https://www.linkedin.com/in/akanksha-singh-868b34248'
		},
		{
			imageURL: '/team/secretaries/ayush.jpg',
		    name: 'Ayush Pandey (ME)',
		    mobile: '9319968066',
		    email: 'ayushpandey939@gmail.com',
		    linkedinURL: 'https://www.linkedin.com/in/ayush-pandey-09b813201/'
		},
		{
			imageURL: '/team/secretaries/divyansh.jpg',
			name: 'Divyansh Sharma (ME)',
			mobile: '7454058754',
			email: 'divyansh2551b@gmail.com',
			linkedinURL: 'https://www.linkedin.com/in/divyanshsharma2551b/'
		},
		{
			imageURL: '/team/secretaries/riya.jpg',
			name: 'Riya Gupta (MCA)',
			mobile: '8318301567',
			email: 'rghg12pg@gmail.com',
			linkedinURL: 'https://www.linkedin.com/in/riya-gupta-8295921b'
		},
		{
			imageURL: '/team/secretaries/priyanka.jpg',
			name: 'Priyanka Sharma (MBA)',
			mobile: '8218568414',
			email: 'prksharma2019@gmail.com',
			linkedinURL: 'https://www.linkedin.com/in/priyanka-sharma-34a880246'
		},
		{
			imageURL: '/team/secretaries/nihal.jpg',
			name: 'Nihal Sahoo (MBA)',
			mobile: '7008280236',
			email: 'nihalsahoo125@gmail.com',
			linkedinURL: 'https://www.linkedin.com/in/nihal-sahoo-609259230'
		}
	]
	return (
		<div className='current-team'>
			<NavBar />
			<div
				className='heading'
				style={{ backgroundImage: `url("/team/bg.png")` }}>
				<h1>PLACEMENT SECRETARIES</h1>
			</div>
			<div className='list container'>
				<div className='row'>
                    {plSecretaries.map(item => {
						return <Coordinator
						           imageURL={item.imageURL}
						           name={item.name}
						           mobile={item.mobile}
						           email={item.email}
						           linkedinURL={item.linkedinURL}
					            /> 
					})}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Secretaries;

/* <Coordinator
   imageURL='/team/secretaries/.jpg'
   name=''
   mobile=''
   email=''
   linkedinURL=''
/> */
