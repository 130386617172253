/* eslint-disable react/jsx-no-comment-textnodes */
import Carousel from 'react-bootstrap/Carousel';

function GalleryCarousel(props) {
  const {imgUrl} = props;
  return (
    <Carousel>
        {imgUrl.map((item, i) => {
            return (
               <Carousel.Item className='carousel-item' key = {i}> {/* key={i} because to remove "Warning: Each child in a list should have a unique "key" prop." in console */}
                  <img
                    className="d-block w-100 gallery-pics"
                    src = {"/gallery/" + item + ".jpg"}
                    alt= {i}
                  />
               </Carousel.Item>
            )
        })}
    </Carousel>
  );
}

export default GalleryCarousel;