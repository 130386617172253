import React from 'react';
import Navbar from '../common/navbar';
import Footer from '../common/footer';
import GalleryCarousel from './galleryCarousel';

export default function Gallery() {
    let imgSrc = ["pd1", "pd2", "pd3", "pd4", "pd5", "pd6", "pd7", "pd8", "pd9", "pd10", "pd11"]; //pd => placement drive pics in "public/gallery" folder
    return (
        <div className='gallery'>
            <Navbar />
            <div
				className='heading'
				style={{ backgroundImage: `url("/contact-us/bg.png")` }}>
				<h1>Gallery</h1>
			</div>
            <section className='gallery-cell'>
               <div className='container'>
                    <GalleryCarousel imgUrl = {imgSrc}/>
               </div>
            </section>
            <Footer />
        </div>
    );
}
