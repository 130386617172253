import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {apiLink} from '../../../mainurl'


function Tier2() {

  const [tier2,setTier2]=useState("");

  useEffect(() => {
    fetch(
      apiLink+"stats/placed_branchwise_placed_category_wise_graph" ,
      {
        method: "GET",
        headers: {
          'accept': "application/json",
          Authorization: "Bearer "+localStorage.getItem('access-token')
        },
      }
    ).then((response) => response.json())
    .then((data) => {
        setTier2(data);
    });
  }, [])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
   const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Tier-2 Placed Count',
      },
    },
  };
  
  const labels = tier2[0];
  
   const data = {
    labels,
    datasets: [
      {
        label: 'Tier2 Count',
        data: tier2[1],
        backgroundColor: '#100F0F',
      }
    ],
  };


  return <Bar options={options} data={data} />;
}
export default Tier2;
