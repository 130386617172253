import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {apiLink} from '../../../mainurl'


function LowestPackage() {

  const [lowestPack,setLowestPack]=useState("");

  useEffect(() => {
    fetch(
      apiLink+"stats/branchwise_average_package" ,
      {
        method: "GET",
        headers: {
          'accept': "application/json",
          Authorization: "Bearer "+localStorage.getItem('access-token')
        },
      }
    ).then((response) => response.json())
    .then((data) => {
      setLowestPack(data);
    });
  }, [])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
   const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Lowest Package-Branchwise',
      },
    },
  };
  
  const labels = lowestPack.branch;
  
   const data = {
    labels,
    datasets: [
      {
        label: 'Lowest Package',
        data: lowestPack.lowest,
        backgroundColor: '#645CAA',
      }
    ],
  };


  return <Bar options={options} data={data} />;
}
export default LowestPackage;
