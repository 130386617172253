import React from 'react';
import NavBar from './../common/navbar';
import Footer from './../common/footer';

const Administration = () => {
	return (
		<div className='administration'>
			<NavBar />
			<div
				className='heading'
				style={{ backgroundImage: `url("/team/bg.png")` }}>
				<h1>ADMINISTRATION</h1>
			</div>
			<div className='list container'>
				<div className='row'>
					<div className='box'>
						<img src='/team/admin/praveena.jpg' alt='' />
						<div className='member-content'>
							<h1>Dr. Parveen P Terang</h1>
							<h4>Training & Placement Officer</h4>
							<h6>✉ – tpo@jssaten.ac.in</h6>
							<h6>📞 +91 9911382632</h6>
						</div>
					</div>
					<div className='box'>
						<img src='/team/admin/vipul.jpg' alt='' />
						<div className='member-content'>
							<h1>Vipul Saxena</h1>
							<h4>Assistant Training and Placement Officer</h4>
							<h6>✉ – placementsjssate@gmail.com</h6>
							<h6>📞 +91 9599200739</h6>
						</div>
					</div>
					<div className='box'>
						<img src='/team/admin/karan.jpg' alt='' />
						<div className='member-content'>
							<h1>Karan Mahajan</h1>
							<h4>Internship Incharge</h4>
							<h6>✉ – karanmahajan@jssaten.ac.in</h6>
							<h6>📞 +91 7042106106</h6>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Administration;
