import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useState } from 'react';
import { useEffect } from 'react';
import {apiLink} from '../../../mainurl'



function HighestPackage() {
  const [highestPack,setHighestPack]=useState("");
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

   const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Highest Package-Branchwise',
      },
    },
  };

  const labels = highestPack.branch;

   const data = {
    labels,
    datasets: [
      {
        label: 'Highest Package',
        data: highestPack.highest,
        backgroundColor: '#674747',
      }
    ],
  };
  useEffect(() => {
    fetch(
      apiLink+"stats/branchwise_average_package" ,
      {
        method: "GET",
        headers: {
          'accept': "application/json",
          Authorization: "Bearer "+localStorage.getItem('access-token')
        },
      }
    ).then((response) => response.json())
    .then((data) => {
      setHighestPack(data);
    });
  }, [])

  return <Bar options={options} data={data} />;
}
export default HighestPackage;
