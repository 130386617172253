import React, { useState,useEffect } from 'react';
import './Sidebar.css';
import {apiLink} from '../../../mainurl'
import { Button } from '@mui/material';

function Sidebar() {

const [oncampus,setOnCampus]=useState([{}]);
const callAll=()=>{
  fetch(apiLink+"student/home/upcoming_companies" , {
    method: 'GET',
    headers: {
        'accept': 'application/json',
        Authorization: "Bearer "+localStorage.getItem('access-token')
    }
  }).then(response => response.json())
  .then(data => {
    setOnCampus(data)
  })
}


const [t,sett]=useState(true);
  useEffect(() => {
    if(!t){
      callAll();
    }
  }, [t])
  
  const handletoggle=()=>{
    sett(!t);
  }
  return <div>
      <div className='header_company'>ON CAMPUS</div>
            <div className="upcomingcompanies">
            {t?(<Button variant="contained" style={{margin:'0rem 1rem',position: 'relative',top: '20%',transform: 'translateY(-50%)'}} onClick={handletoggle}>View</Button>):<>
            {oncampus?.map(function(oncamp) {return<><div className='eachupcomingcompany'><div className='upcomingcompanyname'>{oncamp.cname}</div>:<div className='datea'>{oncamp?.date?.slice(0,10)?.split('-')?.reverse()?.join('-')}</div></div></>})}
            </>}
            </div>
  </div>;
}

export default Sidebar;
