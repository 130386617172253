import React,{useEffect, useState} from 'react';
import Navbar from '../common/navbar';
import Footer from '../common/footer';
import { Form, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';


const ContactUs = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {
		emailjs.init('zmxtLJK_mxc708L5m');
	}, [])
	
	return (
		<div className='contact-us'>
			<Navbar />
			<div
				className='heading'
				style={{ backgroundImage: `url("/contact-us/bg.png")` }}>
				<h1>Contact Us</h1>
			</div>
			<div className='content container'>
				<div className='address'>
					<img src='/icons/address.svg' alt='' />
					<h2>Our Address</h2>
					<p>Placement Cell JSS Noida</p>
				</div>
				<div className='email'>
					<img src='/icons/email.svg' alt='' />
					<h2>Email Us</h2>
					<p>
						<a
							href='mailto:tpo@jssaten.ac.in'
							target='_blank'
							rel='noreferrer'>
							tpo@jssaten.ac.in
						</a>
					</p>
					{/* <p>
						<a
							href='mailto:placementsjce2022@gmail.com'
							target='_blank'
							rel='noreferrer'>
							placement@sjce.ac.in
						</a>
					</p> */}
				</div>
				<div className='call'>
					<img src='/icons/call.svg' alt='' />
					<h2>Call Us</h2>
					<a href='tel:+91xxxxxxxxxx'>+91 xxxxxxxxxx</a>
				</div>
				<div
					className='linkedin'
					onClick={() =>
						window.open(
							'https://www.linkedin.com/in/dr-parveen-p-terang-90845626/',
							'_blank'
						)
					}>
					<img src='/icons/linkedin.svg' alt='' />
					<h2>LinkedIn</h2>
				</div>
			</div>
			<div className='email-form container'>
				<h2>Get in touch</h2>
				<Form
					onSubmit={async event => {
						event.preventDefault();
						setSuccess('');
						setEmail('');

						try {
							await emailjs.send('service_7m09m9f', 'template_58z2oo8', {
								from_name: name,
								from_email: email,
								reply_to: 'tpo@jssaten.ac.in',
								message: message,
							});
							setSuccess("We've received your email!");
							setEmail('');
							setName('');
							setMessage('');
						} catch (ex) {
							setError('An error occurred, please try again!'+ex);
						}
					}}>
					<Form.Group controlId='name'>
						<Form.Label>Name</Form.Label>
						<Form.Control
							value={name}
							onChange={e => setName(e.currentTarget.value)}
							type='text'
							placeholder='Enter name'
							required
						/>
					</Form.Group>
					<Form.Group controlId='formBasicEmail'>
						<Form.Label>Email address</Form.Label>
						<Form.Control
							value={email}
							onChange={e => setEmail(e.currentTarget.value)}
							type='email'
							placeholder='Enter email'
							required
						/>
					</Form.Group>

					<Form.Group controlId='message'>
						<Form.Label>Message</Form.Label>
						<Form.Control
							value={message}
							onChange={e => setMessage(e.currentTarget.value)}
							as='textarea'
							rows={3}
							required
						/>
					</Form.Group>
					{success && <p className='success'>{success}</p>}
					{error && <p className='error'>{error}</p>}

					<Button variant='success' type='submit'>
						Submit
					</Button>
				</Form>
			</div>
			<div className='map container'>
				<iframe
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.5220962430494!2d77.35658761445903!3d28.614110482425154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5992452d761%3A0xaaa44725147c1507!2sJSS%20Academy%20of%20Technical%20Education!5e0!3m2!1sen!2sin!4v1661744315061!5m2!1sen!2sin'
					allowFullScreen={true}
					loading='lazy'
					title='Placement Cell JSS Noida'
				/>
			</div>
			<Footer />
		</div>
	);
};

export default ContactUs;
