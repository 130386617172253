import React,{useState} from 'react';
import './Sidebar.css';
import {apiLink} from '../../../mainurl'
import { useEffect } from 'react';
import { Button } from '@mui/material';

function Sidebar() {
  const [totalverify,setTotalVerified]=useState(0);
  

    const [total,setTotal]=useState(0);
   

    const callAll=()=>{
      fetch(
        apiLink+"stats/download/student/verified",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer "+localStorage.getItem('access-token')
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setTotalVerified(data);
        })
        
      fetch(
        apiLink+"stats/download/student/total",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer "+localStorage.getItem('access-token')
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setTotal(data);
        })
      }
      const [t,sett]=useState(true);
  useEffect(() => {
    if(!t){
      callAll();
    }
  }, [t])
  
  const handletoggle=()=>{
    sett(!t);
  }
  return <div>
      <div className='header_company'>STUDENT DETAILS</div>
            <div className="upcomingcompanies">
            {t?(<Button variant="contained" style={{margin:'0rem 1rem',position: 'relative',top: '20%',transform: 'translateY(-50%)'}} onClick={handletoggle}>View</Button>):<>

                <div className='eachupcomingcompany'><div className='upcomingcompanyname'>Verified</div>:<div>{totalverify}</div></div>
                <div className='eachupcomingcompany'><div className='upcomingcompanyname'>Total</div>:<div>{total}</div></div>
                </>} 
            </div>
  </div>;
}

export default Sidebar;
