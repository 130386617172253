import React, { useState,useEffect } from 'react';
import './Sidebar.css';
import {apiLink} from '../../../mainurl'
import { Button } from '@mui/material';

function TotalStudent() {

  const [branchwiseCount,setBranchwiseCount]=useState("");
const callAll=()=>{

    fetch(
      apiLink+"stats/placed_branchwise_graph" ,
      {
        method: "GET",
        headers: {
          'accept': "application/json",
          Authorization: "Bearer "+localStorage.getItem('access-token'),
        },
      }
    ).then((response) => response.json())
    .then((data) => {
        setBranchwiseCount(data);
    });
  
}


const [t,sett]=useState(true);
  useEffect(() => {
    if(!t){
      callAll();
    }
  }, [t])
  
  const handletoggle=()=>{
    sett(!t);
  }
  return <div>
      <div className='header_company'>Branch Wise</div>
            <div className="upcomingcompanies">
            {t?(<Button variant="contained" style={{position: 'relative',top: '20%',transform: 'translateY(-50%)'}} onClick={handletoggle}>View</Button>):<>
            {branchwiseCount[2]?.map(function(oncampm,i) {return<><div className='eachupcomingcompany'><div className='upcomingcompanyname'>{branchwiseCount[2][i]}</div>:<div className='datea'>{branchwiseCount[3][i]}</div></div></>})}
            </>}
            </div>
  </div>;
}

export default TotalStudent;
