import React from 'react';
import NavBar from './../common/navbar';
import Footer from './../common/footer';
import Coordinator from './common/coordinator';

const CurrentTeam = () => {
	return (
		<div className='current-team'>
			<NavBar />
			<div
				className='heading'
				style={{ backgroundImage: `url("/team/bg.png")` }}>
				<h1>CORE TEAM</h1>
			</div>
			<div className='list container'>
				{/* <h1>PLACEMENT WEBSITE TEAM</h1> */}
				<div className='row'>
					<Coordinator
						imageURL='/team/core/varsha.jpg'
						name='Varsha Singh'
						mobile='7466062830'
						email='varsha160530@gmail.com'
						linkedinURL='https://www.linkedin.com/in/varsha-singh-37411720a/'
					/>
					<Coordinator
						imageURL='/team/core/tarun.jpg'
						name='Tarun Kumar'
						mobile='9086030586'
						email='tarunkumar1999one@gmail.com'
						linkedinURL='https://www.linkedin.com/in/tarun-kumar-a18b131b1'
					/>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default CurrentTeam;
