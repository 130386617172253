import React from 'react';
import Navbar from '../common/navbar';
import Footer from '../common/footer';
import { Carousel, Button } from 'react-bootstrap';

const AboutUs = () => {
	return (
		<div className='about-us'>
			<Navbar />

			{/* about nsut first section */}
			<section
				className='wrapper'
				style={{ backgroundImage: `url("/campus/bloom.jpg")` }}>
				<div className='container'>
					<div className='row px-2'>
						<div className='col-12 col-md-6 py-3 content'>
							<h2>About JSS Noida </h2>
							<p>
							   JSS Noida is a top-grade engineering institute functioning from its 
							   Campus located at Gautam Buddha Nagar Noida Sector-62. The Institute 
							   has an area of 28 acres lush green campus with modern lab facilities 
							   and a green ambiance all around.
							</p>
							<p>
							   The Academy has been awarded top rankings by many agencies including Hindustan Times. 
							   The excellent placement opportunities are visible with more than 80 companies visiting 
							   the institution for Placement during the year 2014. JSSATEN is an Institution with a 
							   difference where emphasis has been laid on the principles of harmonious living, high 
							   morals and ethical values besides excelling in technical subjects. The Institute attracts 
							   students from far interior parts of U.P. and other neighbouring States. JSSATEN also produces 
							   excellent group of students, who achieve highest rankings in GATE, CAT and other competitive 
							   examinations. On the average more than 100 students qualify in the GATE every year. The students 
							   also have excelled in extracurricular activities and have won appreciations and also prizes at 
							   national level. JSSATEN is governed and blessed by His Holiness Sri Shivarathri Deshikendra Swamiji, President, JSS Mahavidyapeetha.
							</p>
						</div>
						<div className='col-12 col-md-6 '>
							<img
								src='/campus/jssNoida.jpg'
								alt='Placement Process'
								className='img-fluid'
							/>
						</div>
					</div>
				</div>
			</section>
			<section className='placement-cell'>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<h2>About The Placement Cell</h2>
							<p>
								JSS Noida facilitates the students with the opportunities
								to procure the placement as per their requirements and skills
								.The Placement cell plays a massive role in acting as an
								interface between Companies and the students. Placement cell
								along with the campus' students skills as a collective is the
								driving forces behind on-campus recruitments.
							</p>
							<p>
								The Placement Cell works round the year to encourage contacts
								between companies and graduates. Providing students with proper
								interaction should be an important responsibility of an
								institution and our campus also has the same belief. The vision
								of our placement cell is to shape up students with Competencies
								and Employability Skills to become part of Top Notch Companies
							</p>
							<p>
								The Placement Cell keeps organising skills development and
								guidance programmes for the students. The Cell arranges
								programmes like Aptitude skills (Verbal Ability, Quantitative
								and Logical Reasoning) development, tech skills
								development(through coding competitions etc), Resume Writing,
								Interview Skills, Group Discussions, overall personality (Goal
								Setting, Confidence Building, Motivation, Positive Thinking,
								Flexibility and other soft skills) development, etc for students
								welfare.The Cell works in direction of counselling and guiding
								the learners for their overall development be it hard or even
								soft skills.
							</p>
							<p>
								The Training And Placement Cell is composed of certain teams
								like training,Research and Development, Person Of Contact(POC)
								among others in order to make sure the smooth conducting of all
								the aspects of our responsibilities. These teams have their own
								defined role in the Train, connect and contact process. Since
								placement cell is working for the students, there has to be
								certain transparency in our working which we always take care of
								by ensuring campus students' involvement in a lot of decisive
								processes so that we know what they require and what should we
								include in our processing.
							</p>
							<br />
						</div>
						<div className='col-12'>
							<Carousel>
								<Carousel.Item>
									<img
										className='d-block w-100'
										src='/campus/about.jpeg'
										alt='First slide'
									/>
								</Carousel.Item>
							</Carousel>
						</div>
					</div>
				</div>
			</section>
			{/* end section */}
			<Footer />
		</div>
	);
};

export default AboutUs;
